/*************************************************************Classes*************************************************************/
.App {
  text-align: center;
}

.page{
  display: flex;
  flex-direction: column;
}
.top{
  background: #286868;
  background-size: cover;
  height: 25rem;
  color: white;
}
.top .top-text-container{
  width: 100%;
}
.top .top-text{
  width: 80%;
  font-size: 1.5rem;
  text-align: left;
  position: relative;
  top: 3rem;
  left: 5rem;
}

.button, .login-button{
  min-width: 100px;
  width: fit-content;
  height: 40px;
  margin: 0.3rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 0.2s all;
}
.button:hover{
  scale: 1.05;
  background-color: rgba(46, 46, 46, 0.542);
  color: whitesmoke;
}

.login-button{
  color: white;
  background-color: rgb(68, 104, 180);
  font-size: 0.9rem;
  height: 35px;
}

.title-container{
  height: fit-content;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
  color: #F4AE3F;
}
.subtitle-container{
  height: fit-content;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 1rem;
  text-align: left;
  color: rgb(88, 88, 88);
}
.bck-y{
  background-color: rgb(212, 171, 56);
}
.bck-g{
  background-color: rgb(9, 75, 50);
}
.text{
  font-size: 1.3rem;
  line-height: 1.7;
}
.modal-content{
  width: 280px;
}
.modal-content .title{
  text-align: center;
  margin: 1rem 0;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.3;
}
.modal-content .subtitle{
  text-align: center;
  white-space: normal;
  margin: 0.8rem 0;
  font-size: 1.3rem;
}
.text-link{
  font-size: 1.5rem;
  line-height: 1.4;
}
.content{
  justify-content: center;
  overflow-x: auto;
  scrollbar-color: rgb(255, 255, 255) #DFDFDF  ;
  scrollbar-width: thin;
}
.content:hover{
  
}
/*************************************************************Shortcuts*************************************************************/
.al-it-ce{
  align-items: center;
}
.center{
  align-items: center;
  justify-content: center;
}
.flexcol{
  display: flex;
  flex-direction: column;
}
.flexrow{
  display: flex;
  flex-direction: row;
}
.flexwrap{
  flex-wrap: wrap;
}
.h100{
  height: 100%;
}
.h20vh{
  height: 20vh;
}
.h35vh{
  height: 35vh;
}
.w100{
  width: 100%;
}
.w50{
  width: 50%;
}
.w30{
  width: 30%;
}
.marg5{
  margin: 0.5rem;
}
.pad5{
  padding: 0.5rem;
}
.pad10{
  padding: 1rem;
}
.text-j{
  text-align: justify;
}
.text-al-le{
  text-align: left;
}

/*************************************************************TOPBAR*************************************************************/
#topbar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  padding: 0.3rem;
  border-bottom: 1px solid rgb(177, 177, 177);
}
#topbar img{
  width: 200px;
  height: auto;
}
#topbar .login-button{
  margin-right: 1rem;
}

/*************************************************************NAVBAR*************************************************************/
#navbar{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 0.3rem;
  font-size: 1.2rem;
}
#navbar ul{
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-right: 1rem;
  overflow-x: auto;
  scrollbar-color: rgb(255, 255, 255) #DFDFDF;
  scrollbar-width: thin;
}
#navbar ul li{
  width: fit-content;
  padding: 0.5rem;
  margin: 0 0.2rem;
  border-radius: 5px;
}
#navbar ul li:hover{
  background-color: rgba(255, 255, 255, 0.2);
}
/*************************************************************HOME*************************************************************/
#home{

}
#carrousel{
  transition: all 1s;
  color: white;
  background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url('./assets/images/home-top-background.png');
  background-size: cover;
}
#home #topbar{
  background-color: none;
}
#home .top{
  height: 35rem;
}
#home .top-text p{
  margin: 0.5em 0;
  line-height: 1.3;
}
#home-1{
  line-height: 1.3;
}
#home-1 .content{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}
#home-1 .content .card{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
  width: 90%;
  min-width: 320px;
  text-align: left;
  background-color: rgb(235, 235, 235);
  border-radius: 5px;
  text-align: justify;
}
#home-1 .content .card .card-img-container{
  height: fit-content;
  width: 400px;
  text-align: center;
}
#home-1 .content .card img{
  height: auto;
  width: 200px;
}
#home-1 h3{
  margin: 1rem 0;
}
#home-2 img{
  width: 100px;
  height: auto;
  max-height: 100px;
  margin: 1rem 2rem;
}
#home-3 .content{
  margin: 1rem 5rem;
  border-radius: 5px;
  border: 1px solid rgb(100, 100, 100);
}
#home-3 .subtitle-container{
  width: auto;
  background-color: rgb(235, 235, 235);
}
#home-3 .subcontent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 1rem;
}
#home-3 .subcontent .bck-y{
  width: 60%;
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
}
#home-3 .subcontent .bck-g{
  width: 60%;
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
}
#home-3 img{
  border-radius: 50%;
  height: auto;
  min-height: 100px;
  width: 100px;
}

/*************************************************************BROCHURE*************************************************************/
#brochure-1 .subcontent{
  margin: 1rem;
}

/*************************************************************ABOUT*************************************************************/
#about-1 .content{
  display: flex;
  flex-direction: row;
  padding: 0 2rem;
}
#description{
  font-size: 1.5rem;
}
#about-2 .subtitle-container{
  white-space: nowrap;
}
#about-2 h3{
  text-align: left;
  font-size: 1rem;
  color: rgb(40, 104, 104);
}
#about-3 .content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: fit-content;
  padding: 1rem;
}
#about-3 .content > div{
  margin: 0 2rem;
}
#about-3 img{
  border-radius: 50%;
  height: auto;
  min-height: 100px;
  width: 100px;
}
#about-3 h4{
  width: 100px;
}

/*************************************************************SERVICES*************************************************************/
#services{

}

#services-1 .content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

#services-1 .content .card{
  width: 30%;
  background-color: rgb(235, 235, 235);
  padding: 1.5rem;
  margin: 0.8rem;
  min-width: 200px;
  vertical-align: middle;
  border-radius: 5px;
  text-align: center;
}
#services-2 .content{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 2rem;
  padding: 1rem;
}
#services-2 img{
  height: 300px;
  width: auto;
  border-radius: 5px;
}
#services-3 img{
  width: 200px;
  height: auto;
  margin: auto;
  border-radius: 5px;
}

/*************************************************************PUBLICATIONS*************************************************************/
#pub-1{
  min-height: 35vh;
}
#pub-1 .content{
  padding: 0 3rem;
}
#pub-1 .list-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
#pub-1 li{
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0.5rem 0;
}
#pub-1 li input[type=checkbox]{
  margin: 0 0.5rem;
  width: 1.4rem;
  height: 1.4rem;
}
#pub-1 svg{
  margin: 0 0.5rem;
}
#pub-1 .button-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}
#pub-form .modal-content{
  width: 500px;
}
#pub-form{
  font-family: 'Times New Roman', Times, serif;
}
#pub-form .input-group{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px;
  width: 100%;
}
#pub-form input, #pub-form textarea{
  padding: 0.5rem;
  width: 100%;
  border-radius: 5px;
}
#pub-form button{
  width: 100%;
  margin: auto;
}
#pub-form .msg{
  font-size: 18px;
}

/*************************************************************CONTACT*************************************************************/
#contact{

}

#contact-1 .title{
  font-size: 1.7rem;
  font-weight: 600;
  text-align: left;
  margin: 0 0 0.5em 0;
  color: #F4AE3F;
}

#contact-1 .content{
  display: flex;
  flex-direction: row;
  padding: 1rem;
  font-size: 0.9rem;
}

#contact-1 .content > div{
  margin: 0 1rem;
  width: 50%;
}

#contact-1 .subcontent{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: left;
}
#contact-1 .subcontent p{
  margin: 0 2rem;
}

#contact-1 form{
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
#contact-1 input, #contact-1 textarea{
  padding: 0.2rem;
  border: none;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.2rem;
}

#contact form .button{
  background-color: black;
  color: white;
  margin: 0.9rem;
}
/*************************************************************FOOTER*************************************************************/
#footer{
  min-height: 50px;
  height: fit-content;
  padding: 1rem;
}
#footer img{
  width: 200px;
  height: auto;
}
@media (max-width:350px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  html{
    font-size: 10px;
  }
  #publications .top-text{
    font-size: 1.21rem;
  }
  #contact .top-text{
    font-size: 1.21rem;
  }
  #home .top .top-text{
    font-size: 1.2rem;
  }
  #home-1 .content .card .card-img-container{
    width: 70vw;
  }
  #home-1 .content .card h3{
    width: 80vw;
    margin: 0 auto;
  }
}
@media (max-width:480px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  html{
    font-size: 10px;
  }
  #about-1 .content{
    flex-direction: column-reverse;
  }
  #home .top .top-text p{
    font-size: 1.2rem;
  }
}
@media (max-width:660px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  html{
    font-size: 10px;
  }
  #home .top{
    height: 35rem;
  }
  .top-text-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .top .top-text{
    margin: auto;
    width: 70%;
    padding-top: 2rem;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
  }
  #home-1 .content .card{
    flex-direction: column;
    text-align: center;
  }
  #home-1 .content .card .card-img-container{
    margin: 0 0 1rem 0;
  }
  #home-1 .content .card img{
    height: 75px;
    width: auto;
  }

  #about-3 .content{
    justify-content: flex-start;
  }
}
@media (max-width:800px) { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
  html{
    font-size: 11px;
  }
  #services-2 .content{
    flex-direction: column-reverse;
  }
  #services-2 .content > div{
    width: 90%;
  }
  #services-2 .content img{
    height: 200px;
    width: auto;
  }
  #home-1 .content .card h3{
    font-weight: 500;
  }
  #home-3 .subtitle-container{
    text-align: center;
    margin: 0 auto;
  }
  #home-3 .subcontent{
    flex-direction: column;
  }
  #home-3 .subcontent .bck-y{
    width: 200px;
  }
  .content{
    justify-content: flex-start;
  }
  #contact-1 .content{
    flex-direction: column-reverse;
    align-items: center;
  }
  #contact-1 .content > div{
    width: 80%;
    margin: 1rem 0;
  }
}
@media (max-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 

}
@media (max-width:1025px) { /* big landscape tablets, laptops, and desktops */ 

}
@media (max-width:1281px) { /* hi-res laptops and desktops */ 

} 

@media (min-height:0px) and (max-height: 481px) { /* smartphones, iPhone, portrait 480x320 phones */ 

}
@media (min-height:350px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */

}
@media (min-height:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */

}
@media (min-height:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 

}
@media (min-height:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 

}
@media (min-height:1025px) { /* big landscape tablets, laptops, and desktops */ 

}
@media (min-height:1281px) { /* hi-res laptops and desktops */ 

} 