.overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.7;
    z-index: 1000;
}
.modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFF;
    padding: 2rem;
    z-index: 1000;
    border-radius: 0.4rem;
    width: fit-content;
}

#close-modal{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1001;
}
#close-modal:hover{
    color: #F4AE3F
}