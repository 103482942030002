html{
  font-size: 14px;
}
body {
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  margin: 0;
  padding: 0;
  scrollbar-color: rgb(255, 255, 255) #DFDFDF  ;
  scrollbar-width: thin;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul{
  list-style-type: none;
  margin: 0;
}
li{
  margin: 0;
}
a{
  text-decoration: none;
  color: inherit;
}
textarea{
  resize: none;
  font-size: inherit;
}
section{
  display: flex;
  flex-direction: column;
  margin: 0.2em auto;
  width: 100%;
  padding: 2em 0;
  min-height: 50px;
  height: fit-content;
  background-color: rgb(223, 223, 223);
}
footer{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(40, 104, 104);
}

