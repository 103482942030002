.msg{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 15px;
    height: auto;
    width: 100%;
    border: none;
    background: inherit;
    border-radius: 5px;
    font-size: inherit;
    font-weight: 700;
    white-space: normal;
    transition: 0.5s;
    margin: 0.3rem 0;
}
.err{
    color: rgba(250, 0, 0, 0.486);
    width: 100%;
}
.success{
    color: rgba(9, 141, 158, 0.75);
}
.alert{
    color: rgba(255, 241, 45, 0.75);
}